<template>
  <v-container id="inspire" fluid>
    <v-row class="fill-height">
      <v-col>
        <v-sheet>
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-toolbar class="toolbar-flex" flat>
                <v-btn class="mr-4" color="grey darken-2" outlined @click="setToday">
                  {{ $t('buttons.today') }}
                </v-btn>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey darken-2" outlined v-bind="attrs" v-on="on">
                      <span>{{ $t(typeToLabel[type]) }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = changeTypeOfCalendar('day')">
                      <v-list-item-title> {{ $t('filters.day') }} </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = changeTypeOfCalendar('week')">
                      <v-list-item-title> {{ $t('filters.week') }} </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = changeTypeOfCalendar('month')">
                      <v-list-item-title> {{ $t('filters.month') }} </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div class="toolbar-flex-div"></div>
                <v-btn color="grey darken-2" fab small text @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn color="grey darken-2" fab small text @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ formatTitle($refs.calendar.title) }}
                </v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6">
              <v-autocomplete
                v-model="client_id"
                :items="clients"
                clearable
                item-text="company_name"
                item-value="id"
                :label="$t('filters.filterByClient')"
                @change="loadAllOrders"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6">
              <v-autocomplete
                  v-model="organisation_id"
                  :items="organisations"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$t('filters.filterByOrganisation')"
                  @change="loadAllOrders"
                  :no-data-text="$t('select.noDataAvailable')"
                  @focus="$event.target.click()"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6">
              <v-autocomplete
                v-model="collector_id"
                :items="collectors"
                clearable
                item-text="name"
                item-value="id"
                :label="$t('filters.filterByDriver')"
                @change="loadAllOrders"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            :events="events"
            :interval-format="intervalFormat"
            :type="type"
            color="primary"
            interval-count="48"
            interval-height="60"
            interval-minutes="30"
            @change="updateRange"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:time="showEvent"
            :weekday-format="getDay"
            :month-format="getMonth">
            <template v-slot:event="{ event }">
              <div>
                <p style="margin-left: 5px">{{ event.name }} {{ event.start_display_formated }}</p>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>

      <order-modal :disabled="true" :selectedElement="selectedElement" @loadAllOrders="loadAllOrders"></order-modal>
    </v-row>
  </v-container>
</template>

<script>
import { defaultOrder } from '@/mixins/default-items';
import OrderModal from '@/components/orders/OrderModal.vue';
import i18n from '@/i18n/i18n';

export default {
  name: 'CalendarView',
  components: { OrderModal },
  props: ['order_id'],
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'filters.month',
      week: 'filters.week',
      day: 'filters.day',
    },
    selectedElement: null,
    events: [],
    collector_id: null,
    collectors: [],
    client_id: null,
    clients: [],
    organisation_id: null,
    organisations: [],
    min: null,
    max: null,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },

  created() {
    this.selectedElement = Object.assign({}, defaultOrder);
    if (this.order_id) {
      this.showSingleOrder(this.order_id);
    }
    this.getAllCollectors();
    this.getAllClients();
    this.loadAllOrganisations();
  },
  methods: {
    showSingleOrder(id) {
      this.$store.dispatch('orders/getOrder', id).then((res) => {
        res.data.color = this.changeColorBasedOnProposalStatus(res.data?.status);
        this.showEvent({ nativeEvent: false, event: res.data });
      });
    },

    async loadAllOrders() {
      let queryData = {
        from: this.min,
        to: this.max,
        collector_id: this.collector_id,
        client_id: this.client_id,
        organisation_id: this.organisation_id,
      };
      await this.$store.dispatch('orders/getAllOrders', queryData).then((res) => {
        this.events = res.data.map((e) => {
          e.color = this.changeColorBasedOnProposalStatus(e.status);
          e.start = e.pickup_time;
          e.end = e.pickup_time;

          e.name = e.id;
          e.start_display_formated = this.getTime(e.pickup_time);

          return e;
        });
      });
    },

    async loadAllOrganisations() {
      await this.$store.dispatch('organisations/getAllOrganisations').then((res) => {
        this.organisations = res.data;
      });
    },

    getTime(date) {
      const dateTime = new Date(date);

      // Get hours, minutes, and seconds
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();

      // Format the time as HH:mm:ss
      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

      return formattedTime;
    },

    async getAllCollectors() {
      await this.$store.dispatch('users/getAllCollectors').then((res) => {
        this.collectors = res.data.length
          ? res.data.map((user) => {
              user.name = user.first_name + ' ' + user.last_name || 'N/A';

              return user;
            })
          : [];
      });
    },

    async getAllClients() {
      await this.$store.dispatch('clients/getAllClients').then((res) => {
        this.clients = res.data.length
          ? res.data.map((user) => {
              return user;
            })
          : [];
      });
    },

    changeColorBasedOnProposalStatus(proposalStatus) {
      if (proposalStatus === 'client_pending') {
        return '#ECC600';
      } else if (proposalStatus === 'collector_pending') {
        return '#ECC600';
      } else if (proposalStatus === 'accepted') {
        return '#3CB503';
      } else if (proposalStatus === 'deleted') {
        return '#F46D6D';
      } else if (proposalStatus === 'canceled') {
        return '#868686';
      } else if (proposalStatus === 'completed') {
        return '#868686';
      }
      return 'red';
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    setToday() {
      this.focus = new Date();
      this.type = 'day';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      this.selectedElement = Object.assign({}, event);

      if (nativeEvent) {
        nativeEvent.stopPropagation();
      }
    },
    updateRange({ start, end }) {
      const min = new Date(`${start.date}T00:00:00`).toISOString();
      const max = new Date(`${end.date}T23:59:59`).toISOString();
      this.min = min;
      this.max = max;
      this.loadAllOrders();
    },

    intervalFormat(interval) {
      return interval.time;
    },

    changeTypeOfCalendar(type) {
      if (type === 'day' || type === 'week') {
        setTimeout(() => this.$refs.calendar.scrollToTime(475), 100);
      }
      return type;
    },
    getDay(date) {
      const weekdayTranslations = {
        0: 'weekDays.sunday',
        1: 'weekDays.monday',
        2: 'weekDays.tuesday',
        3: 'weekDays.wednesday',
        4: 'weekDays.thursday',
        5: 'weekDays.friday',
        6: 'weekDays.saturday',
      };

      return i18n.t(weekdayTranslations[date.weekday]).slice(0, 3);
    },
    getMonth(date) {
      const monthsTranslations = {
        1: 'months.jan',
        2: 'months.feb',
        3: 'months.mar',
        4: 'months.apr',
        5: 'months.may',
        6: 'months.jun',
        7: 'months.jul',
        8: 'months.aug',
        9: 'months.sep',
        10: 'months.oct',
        11: 'months.nov',
        12: 'months.dec',
      };

      return i18n.t(monthsTranslations[date.month]).slice(0, 3);
    },
    formatTitle(title) {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const monthsTranslations = {
        0: 'months.jan',
        1: 'months.feb',
        2: 'months.mar',
        3: 'months.apr',
        4: 'months.may',
        5: 'months.jun',
        6: 'months.jul',
        7: 'months.aug',
        8: 'months.sep',
        9: 'months.oct',
        10: 'months.nov',
        11: 'months.dec',
      };

      let month = title.slice(0, -5);
      const year = title.slice(-4);

      monthNames.map((item, index) => {
        if (item === month) {
          month = monthsTranslations[index];
        }
      });
      return i18n.t(month) + ' ' + year;
    },
  },
  watch: {
    '$store.state.addedNewDrivingCounter': {
      immediate: false,
      handler() {
        this.loadAllOrders();
      },
    },
    '$store.state.notificationDriving.id': {
      handler() {
        this.showSingleOrder(this.$store.state.notificationDriving.id);
        this.$store.commit('updateNotificationDrivingReload');
        this.loadAllOrders();
      },
      deep: false,
    },
    '$store.state.notificationDriving.reload': {
      handler() {
        if (this.$store.state.notificationDriving.reload) {
          this.showSingleOrder(this.$store.state.notificationDriving.id);
          this.$store.commit('updateNotificationDrivingReload');
          this.loadAllOrders();
        }
      },
      deep: false,
    },
  },
};
</script>

<style>
@media only screen and (max-width: 480px) {
  .toolbar-flex > .v-toolbar__content {
    flex-wrap: wrap;
  }

  .toolbar-flex-div {
    flex-basis: available;
    margin-right: 100%;
  }
}
</style>
