<template>
  <CalendarView :order_id="$route.query.d_id" />
</template>
<script>
import CalendarView from '@/views/orders/CalendarView';

export default {
  name: 'HomePage',
  components: {
    CalendarView,
  },
  data: () => ({}),
  created() {},
  methods: {},
};
</script>
